import Box from '@material-ui/core/Box'
import { Theme } from '@material-ui/core/styles/createTheme'
import makeStyles from '@material-ui/core/styles/makeStyles'
import React from 'react'

const useStyles = makeStyles((_theme: Theme) => ({
  main: {
    width: '100%',
    minHeight: 600,
    display: 'flex',
    backgroundColor: '#fff',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
  },
}))

export default function NotFound(): JSX.Element {
  const classes = useStyles()

  return (
    <Box className={classes.main}>
      <img
        style={{ maxWidth: '70vw', height: 'auto' }}
        width="100%"
        height="600px"
        alt="not found"
        src="/assets/images/404.svg"
        loading="lazy"
      />
    </Box>
  )
}
