import React, { ReactElement, useEffect } from 'react'
import { useHistory, useLocation } from 'react-router-dom'

import { sendCodeForSISECI } from '../../../services/auth'

interface Props {
  children: JSX.Element
}

export default function BlankLayout(props: Props): ReactElement {
  const { children } = props
  const history = useHistory()
  const location = useLocation()
  useEffect(() => {
    const url = (window as any).location.search
    const { pathname, search } = location
    const query = new URLSearchParams(url)
    const code: any = query.get('code')
    if (code) {
      const urlStorage = pathname + search
      localStorage.setItem('pathname_redirect_gov', urlStorage)
      sendCodeForSISECI(code, history)
    }

    const script = document.createElement('script')
    script.src = `https://www.google.com/recaptcha/api.js?render=${process.env.REACT_APP_RECAPTCHA}`
    script.defer = true
    script.addEventListener('load', () => {})
    document.body.appendChild(script)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])
  return (
    <>
      <div>{children}</div>
    </>
  )
}
