import './index.css'

import {
  createGenerateClassName,
  StylesProvider,
} from '@material-ui/core/styles'
import ThemeProvider from '@material-ui/styles/ThemeProvider'
import React from 'react'
import ReactDOM from 'react-dom'

import App from './App'
import Tags from './Components/MetaTags'
import { getToken } from './services/firebase'
// import * as serviceWorker from './serviceWorker';
import theme from './services/theme-service'

getToken()

const generateClassName = createGenerateClassName({
  productionPrefix: 'portal',
  disableGlobal: true,
})

ReactDOM.render(
  <React.StrictMode>
    <StylesProvider generateClassName={generateClassName}>
      <ThemeProvider theme={theme}>
        <Tags />
        <App />
      </ThemeProvider>
    </StylesProvider>
  </React.StrictMode>,
  document.getElementById('root'),
)

// serviceWorker.register();
