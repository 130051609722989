import { AxiosResponse } from 'axios'

import { apisiseci, apisso } from './api'
import { postRemoveDevice } from './device'
import { UserData, UserDataMti } from './user'

export const saveTokens = (tokenSSO: string, tokenSISECI: string): void => {
  localStorage.setItem('gov_access_token_sso', tokenSSO)
  localStorage.setItem('gov_access_token_siseci', tokenSISECI)
}

export const clearStorage = (): void => {
  localStorage.removeItem('gov_access_token_sso')
  localStorage.removeItem('gov_access_token_siseci')
  localStorage.removeItem('pathname_redirect_gov')
  localStorage.removeItem('gov_user_data')
  sessionStorage.removeItem('@data-complements')
}

export const getPathToRedirectLogin = (redirectPathname: string): string => {
  if (process.env.REACT_APP_SSO_LOGIN_PROVIDER === 'primary') {
    // eslint-disable-next-line @typescript-eslint/restrict-template-expressions
    return `${process.env.REACT_APP_SSO}/sso/cidadao?client_id=${process.env.REACT_APP_CLIENT_ID}`
  }
  // eslint-disable-next-line @typescript-eslint/restrict-template-expressions
  return `${process.env.REACT_APP_SSO_SECUNDARY}/auth?client_id=${process.env.REACT_APP_CLIENT_ID}&response_type=${process.env.REACT_APP_RESPONSE_TYPE}&redirect_uri=${redirectPathname}`
}

export const loginRedirectPrivider = (pathname: string): void => {
  clearStorage()
  localStorage.setItem('pathname_redirect_gov', pathname)
  const redirectPathname = localStorage.getItem('pathname_redirect_gov')

  ;(window as any).open(getPathToRedirectLogin(redirectPathname), '_self')
}

export const logout = async () => {
  const tokenSiseci: string | null = localStorage.getItem(
    'gov_access_token_siseci',
  )
  const deviceToken: string | null = localStorage.getItem(
    'gov_access_device_token',
  )

  if (tokenSiseci && deviceToken) {
    await postRemoveDevice(deviceToken, tokenSiseci)
  }
  if (process.env.REACT_APP_SSO_LOGIN_PROVIDER === 'primary') {
    clearStorage()
    // eslint-disable-next-line @typescript-eslint/restrict-template-expressions
    ;(window as any).open(
      `${process.env.REACT_APP_SSO}/sso/sair?client_id=${process.env.REACT_APP_CLIENT_ID}&redirect_uri=${window.location.href}`,
      '_self',
    )
  } else {
    clearStorage()
    ;(window as any).open(
      // eslint-disable-next-line @typescript-eslint/restrict-template-expressions
      `${process.env.REACT_APP_SSO_SECUNDARY}/logout?client_id=${process.env.REACT_APP_CLIENT_ID}&response_type=${process.env.REACT_APP_RESPONSE_TYPE}&redirect_uri=${window.location.href}`,
      '_self',
    )
  }
}

export const getDataUserSso = (token: string): Promise<AxiosResponse> =>
  apisso.get('/cidadaos/pro/', {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  })

export const getDataUserMti = (
  token: string,
  access_token_mti: string,
): Promise<AxiosResponse> =>
  apisiseci.get('/profile/externo/', {
    headers: {
      Authorization: `Token ${token}`,
      'Authorization-Externo': access_token_mti,
    },
  })

export const validateUserSSO = (code: string): Promise<AxiosResponse> =>
  apisso.post(
    '/validar_cidadao/',
    {
      code,
    },
    {
      headers: {
        'Content-Type': 'application/json',
      },
    },
  )

export const loginSigp = (
  code: string,
  redirect_uri?: string,
): Promise<AxiosResponse> => {
  if (process.env.REACT_APP_SSO_LOGIN_PROVIDER === 'primary') {
    return apisiseci.post('/login-sigp/', {
      code,
    })
  }
  return apisiseci.post(
    '/login/externo/',
    {
      code,
      redirect_uri: redirect_uri || process.env.REACT_APP_REDIRECT_URI,
    },
    {
      headers: {
        // eslint-disable-next-line @typescript-eslint/restrict-template-expressions
        Authorization: process.env.REACT_APP_API_SISECI_KEY,
      },
    },
  )
}

type ResumeUserData = Pick<
  UserData,
  'nome' | 'data_nascimento' | 'nome_mae' | 'cpf' | 'contato'
>

const mtiDataUserAdapter = (data: UserDataMti): ResumeUserData => {
  const dataF: ResumeUserData = {
    nome: data.name,
    data_nascimento: data.dataNascimento,
    nome_mae: data.nomeMae,
    cpf: data.preferred_username || data.username || data.cpf,
    contato: {
      email: data.email,
    },
  }
  return dataF
}

export const getUserData = async (token: string, access_token_mti: string) => {
  try {
    if (process.env.REACT_APP_SSO_LOGIN_PROVIDER === 'primary') {
      const { data } = await getDataUserSso(token)
      // eslint-disable-next-line @typescript-eslint/no-unsafe-return
      return data[0]
    }
    const { data } = await getDataUserMti(access_token_mti, token)
    return mtiDataUserAdapter(data)
  } catch (error) {
    // Verifica se o erro possui um código de status e se é 401
    if (error.response && error.response.status === 401) {
      clearStorage()
      throw new Error('Unauthorized: Token expired or invalid')
    }
    throw error
  }
}

export const sendCodeForSISECI = async (code: string, history: any) => {
  const pathname = localStorage.getItem('pathname_redirect_gov')
  try {
    const { data } = await loginSigp(
      code,
      `${window.location.origin}${window.location.pathname}`,
    )

    // TODO: verificar o perfil do cidadão no jwt

    if (data.key && data.access_token) {
      saveTokens(data.access_token, data.key)
      const spathname = pathname === '/' ? '/' : pathname
      history.push(spathname || '/', {
        message: data.message ? data.message : '',
      })
    }
  } catch (err) {
    clearStorage()
    history.push(pathname || '/', {
      message: 'Erro ao processar o login',
    })
  }
}

export const getImageProfile = async (token: string) =>
  apisso.get('cidadaos/foto/', {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  })

export const getUserDataComplements = async (token: string) =>
  apisso.get('cidadaos/complementar/', {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  })
