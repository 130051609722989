/* eslint-disable react/require-default-props */
import Box from '@material-ui/core/Box'
import { Theme } from '@material-ui/core/styles/createTheme'
import makeStyles from '@material-ui/core/styles/makeStyles'
import Schedule from '@material-ui/icons/Schedule'
import React, { MouseEvent, useContext, useEffect, useState } from 'react'
import { useHistory, useLocation } from 'react-router-dom'

import colorContext from '../../Context/colorContext'

interface NavProps {
  label: string
  route?: string
  active: boolean
  actionPage?: string
  onMouseEnter: (e: MouseEvent) => void
  onMouseLeave: () => void
  icon: {
    name: string
    width: string
    height: string
  }
  list: [
    {
      id: number
      titulo: string
      url: string
      slug?: string
      isExternal: boolean
      pagina_slug: string
      pagina_publicado: boolean
    },
  ]
}

const Links = ({
  label,
  route,
  active,
  actionPage,
  onMouseEnter,
  onMouseLeave,
  icon,
  list,
}: NavProps): JSX.Element => {
  const { colors } = useContext(colorContext)
  const [isClicked, setIsClicked] = useState<boolean>(false)
  const location = useLocation()
  const useStyles = makeStyles((theme: Theme) => ({
    dropdown: {
      position: 'absolute',
      top: 67,
      left: 0,
      width: '100%',
      height: 'auto',
      backgroundColor: colors.colorBackground,
      zIndex: 5,
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'center',
    },
    iconNavBar: {
      marginRight: 10,
    },
    boxLink: {
      display: 'flex',
      alignItems: 'center',
      minWidth: 215,
      width: 'auto',
    },
    links: {
      fontSize: theme.typography.pxToRem(18),
      fontWeight: 700,
      color: active || isClicked ? colors.textAccentColor : colors.accentColor,
      textTransform: 'uppercase',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      '&:hover': {
        color: '#fff',
        cursor: 'pointer',
      },
    },
    sessionOptions: {
      maxWidth: '1320px',
      width: '100%',
      margin: '15px 0px',
      height: 280,
      overflowY: 'auto',
      display: 'flex',
      flexDirection: 'column',
      alignContent: 'start',
      flexWrap: 'wrap',
    },
    textLink: {
      fontFamily: 'Roboto',
      fontSize: theme.typography.pxToRem(18),
      fontWeight: 400,
      textDecoration: 'none',
      color: colors.textBodyBack,
      display: 'flex',
      margin: '11px 0px',
      marginLeft: 10,
      marginRight: 15,
      cursor: 'pointer',
    },
  }))
  const classes = useStyles()
  const history = useHistory()
  useEffect(() => {
    if (location.pathname !== `/${actionPage}/servicos`) {
      setIsClicked(false)
    }
  }, [actionPage, location.pathname])

  useEffect(() => {
    if (location.pathname.includes(route)) {
      setIsClicked(true)
    } else {
      setIsClicked(false)
    }
  }, [location.pathname, route])

  return (
    <div onMouseEnter={onMouseEnter}>
      <Box
        className={classes.links}
        onClick={() => {
          if (actionPage) {
            history.push(`/${actionPage}/servicos`)
            setIsClicked(true)
          }
        }}
      >
        <img
          className={classes.iconNavBar}
          width={icon.width}
          height={icon.height}
          src={`${process.env.PUBLIC_URL}/assets/images/${icon.name}.svg`}
          alt={label}
          loading="lazy"
        />
        {label}
      </Box>
      {active && (
        <div onMouseLeave={onMouseLeave} className={classes.dropdown}>
          <div className={classes.sessionOptions}>
            {list &&
              list
                .filter((li) => li.pagina_publicado === true)
                .map((li, index) => (
                  <div key={`${li}-key-${index}`} className={classes.boxLink}>
                    {li.titulo === 'Poupa Tempo RJ' ? (
                      <Schedule style={{ color: '#6e6e6e' }} />
                    ) : (
                      <img
                        width="21px"
                        height="21px"
                        src={`${process.env.PUBLIC_URL}/assets/icons/search.svg`}
                        alt="seach-icon"
                        loading="lazy"
                      />
                    )}
                    {!route ? (
                      <>
                        <a
                          className={classes.textLink}
                          href={
                            li.isExternal || li.url
                              ? li.url
                              : `${process.env.PUBLIC_URL}/pagina/${li.pagina_slug}`
                          }
                          target={li.isExternal ? '_blank' : '_self'}
                          rel="noreferrer"
                        >
                          {li.titulo}
                        </a>
                      </>
                    ) : (
                      <Box
                        onClick={() =>
                          (window.location.href = `/${route}/servicos/${li.slug}/categoria/${li.id}`)
                        }
                        className={classes.textLink}
                      >
                        {li.titulo}
                      </Box>
                    )}
                  </div>
                ))}
          </div>
        </div>
      )}
    </div>
  )
}

export default Links
