import { AxiosResponse } from 'axios'

import { apiformflow, apisiseci } from './api'

export const getService = (
  slug: string,
  page?: number,
): Promise<AxiosResponse> => {
  const queries = new URLSearchParams({ slug })

  if (page !== undefined) {
    queries.append('page', page.toString())
  }

  return apisiseci.get(`/cms/servicos_busca/?${queries.toString()}`)
}

export const searchServiceOld = (
  slug: string,
  page: number,
): Promise<AxiosResponse> =>
  apisiseci.get(`cms/servicos/?search=${slug.toUpperCase()}&page=${page}`)

export const searchServiceNew = (
  slug: string,
  page: number,
): Promise<AxiosResponse> => {
  const encodedSlug = encodeURIComponent(slug.toUpperCase())
  return apisiseci.get(`cms/servicos_busca/?search=${encodedSlug}&page=${page}`)
}
export const searchServiceForOrgan = (
  slug: string,
  page: number,
): Promise<AxiosResponse> =>
  apisiseci.get(`cms/servicos_busca/?orgao_slug=${slug}&page=${page}`)

export const serviceForCategory = (
  slug: string,
  page: number,
): Promise<AxiosResponse> =>
  apisiseci.get(`cms/servicos_busca/?categoria_slug=${slug}&page=${page}`)

export const getServiceTypeUser = (
  userType: 'Cidadão' | 'Servidor' | 'Empresa' | 'Gestão Pública',
): Promise<AxiosResponse> =>
  apisiseci.get(`/cms/serv_destaques/?publico=${userType}`)

export const serviceSchedules = (): Promise<AxiosResponse> =>
  apisiseci.get('/cms/servicos_busca/?agendavel=true')

export const favoriteService = (
  servico: number,
  token: string,
): Promise<AxiosResponse> =>
  apisiseci.post(
    '/cms/servicos_favoritos/',
    {
      servico,
    },
    {
      headers: {
        Authorization: `Token ${token}`,
      },
    },
  )
export const getFavoriteService = (token: string): Promise<AxiosResponse> =>
  apisiseci.get('/cms/servicos_favoritos/', {
    headers: {
      Authorization: `Token ${token}`,
    },
  })

export const removeFavoriteService = (
  servico: number,
  token: string,
): Promise<AxiosResponse> =>
  apisiseci.delete(`/cms/servicos_favoritos/${servico}`, {
    headers: {
      Authorization: `Token ${token}`,
    },
  })

export const ratingServiceAndComment = (
  service_id: number,
  avaliation: number,
  comments: string,
  token: string,
): Promise<AxiosResponse> =>
  apisiseci.post(
    'cms/avaliar_servico/',
    {
      servico: service_id,
      avaliacao: avaliation,
      comentario: comments,
    },
    {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    },
  )

export const getProfileService = (perfil: string): Promise<AxiosResponse> =>
  apisiseci.get(`/cms/categoria_perfil/?perfil=${perfil}`)

export const getSubCategoriaPerfil = (perfil: string): Promise<AxiosResponse> =>
  apisiseci.get(`/cms/categorias/?perfil=${perfil}`)

export const getCategorias = (id: string): Promise<AxiosResponse> =>
  apisiseci.get(`/cms/categorias/${id}/`)

export const getServicesToCategory = (
  page: number,
  slug: string,
): Promise<AxiosResponse> =>
  apisiseci.get(`/cms/servicos_busca/?page=${page}&categoria_slug=${slug}`)

export const getServicesToSubCategory = (
  page: number,
  slug: string,
): Promise<AxiosResponse> =>
  apisiseci.get(`/cms/servicos_busca/?page=${page}&subcategoria_slug=${slug}`)

export const reportBug = (
  token: string,
  conteudo: string,
  servico_id: string,
): Promise<AxiosResponse> =>
  apisiseci.post(
    '/cms/servicos_erros/',
    {
      conteudo,
      servico: servico_id,
    },
    {
      headers: {
        Authorization: `Token ${token}`,
      },
    },
  )

export const getSubCategoryServices = (
  page: number,
  slug_subcategory: string,
): Promise<AxiosResponse> =>
  apisiseci.get(
    `/cms/servicos_busca/?subcategoria_slug=${slug_subcategory}&page=${page}`,
  )

export const getListApps = (params: {
  nome?: string
  page?: number
}): Promise<AxiosResponse> => {
  if (params.nome) {
    return apisiseci.get('/cms/aplicativos/', {
      params,
    })
  }

  return apisiseci.get('/cms/aplicativos/?todos=Sim', {
    params,
  })
}

export const getApp = (slug: string): Promise<AxiosResponse> =>
  apisiseci.get(`/cms/aplicativos/?slug=${slug}`)

export const validateDocument = (
  id_code: string,
  verify_hash: string,
): Promise<AxiosResponse> =>
  apiformflow.get(
    `/flow/validar-documento?identificador=${id_code}&verificador=${verify_hash}`,
  )
