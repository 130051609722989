interface LogoObjectDataProps {
  titulo: string
  url_imagem: string
  url: string
  tipo: 'Cabeçalho' | 'Rodapé'
}

type PrefixProps = 'Cabeçalho' | 'Rodapé'

export const handleFilter = (
  logoObjectData: LogoObjectDataProps[],
  prefix: PrefixProps,
) =>
  logoObjectData
    ?.filter((data) => data.tipo === prefix)
    .map((logo) => {
      const newData = {
        titulo: logo.titulo,
        url_imagem: logo.url_imagem,
        url: logo.url,
        tipo: logo.tipo,
      }

      return newData
    })

export default { handleFilter }
