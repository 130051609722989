/* eslint-disable jsx-a11y/media-has-caption */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import Box from '@material-ui/core/Box'
import { makeStyles } from '@material-ui/core/styles'
import Pause from '@material-ui/icons/Pause'
import React, { useEffect, useRef } from 'react'

interface Props {
  handlePlay: () => void
  isPlaying: boolean
}

const PlayerComponent = ({ handlePlay, isPlaying }: Props): JSX.Element => {
  const useStyles = makeStyles(() => ({
    buttonPlay: {
      width: 40,
      height: 28,
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      backgroundColor: '#442771',
      borderTopLeftRadius: 10,
      borderBottomLeftRadius: 10,
      padding: 11,
      cursor: 'pointer',
    },
    audio: {
      display: 'none',
    },
  }))
  const classes = useStyles()
  const audioPlayer: any = useRef()

  useEffect(() => {
    if (isPlaying) {
      audioPlayer.current.play()
    } else {
      audioPlayer.current.pause()
    }
  }, [isPlaying])

  return (
    <Box className={classes.buttonPlay} onClick={handlePlay}>
      {isPlaying ? (
        <Pause style={{ fontSize: 12, color: 'white' }} />
      ) : (
        <img
          width="10px"
          height="11px"
          src={`${process.env.PUBLIC_URL}/assets/icons/play.svg`}
          alt="play"
          loading="lazy"
        />
      )}
      <audio
        ref={audioPlayer}
        autoPlay={false}
        src="https://radio.saopaulo01.com.br/8300/stream/"
        className={classes.audio}
      />
    </Box>
  )
}

export default PlayerComponent
