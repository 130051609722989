import { createContext } from 'react'

const refContext = createContext({
  refCategory: null,
  refService: null,
  refEstatistic: null,
  refAttendance: null,
  refNoticias: null,
  refInicio: null,
  refRegistryType: null,
})

export default refContext
