import Box from '@material-ui/core/Box'
import Dialog from '@material-ui/core/Dialog'
import DialogContent from '@material-ui/core/DialogContent'
import DialogTitle from '@material-ui/core/DialogTitle'
import IconButton from '@material-ui/core/IconButton'
import { Theme } from '@material-ui/core/styles/createTheme'
import makeStyles from '@material-ui/core/styles/makeStyles'
import Close from '@material-ui/icons/Close'
import React, { useContext, useEffect, useState } from 'react'

import colorContext from '../../Context/colorContext'
import { getNotices } from '../../services/avisos'
import { onMessageListener } from '../../services/firebase'

export interface INotice {
  titulo: string
  conteudo: string
  id: number
}

export default function ModalAlertsDialog(): JSX.Element {
  const { colors } = useContext(colorContext)
  const useStyles = makeStyles((theme: Theme) => ({
    root: {
      '&.MuiDialogContent-root': {
        backgroundColor: colors.colorBackgroundSecundary,
      },
    },
    closeButton: {
      position: 'absolute',
      right: theme.spacing(1),
      top: theme.spacing(1),
      color: colors.textAccentColor,
    },
    titleDialog: {
      color: colors.textAccentColor,
      backgroundColor: colors.colorBackgroundSecundary,
      paddingTop: 20,
      paddingBottom: 20,
      paddingRight: 40,
    },
    contentDialog: {
      color: colors.textBody,
      backgroundColor: colors.colorBackground,
      width: '100%',
    },
  }))
  const classes = useStyles()
  const [open, setOpen] = useState<boolean>(false)
  const [notice, setNotice] = useState<INotice | undefined>()

  const loadCurrentNotices = async () => {
    try {
      const { data } = await getNotices()
      if (data) {
        setNotice(data)
      }
    } catch (error) {
      console.log(error)
    }
  }
  onMessageListener()
    .then(() => {
      loadCurrentNotices()
    })
    .catch((err) => console.log('failed: ', err))
  const handleClose = () => {
    setOpen(false)
  }

  useEffect(() => {
    loadCurrentNotices()
  }, [])

  useEffect(() => {
    const noticeViewed = localStorage.getItem('notice-visualized')
    if (notice && noticeViewed !== notice.id.toString()) {
      localStorage.setItem('notice-visualized', notice.id.toString())
      setOpen(true)
    } else {
      setOpen(false)
    }
  }, [notice])

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      aria-labelledby="alert-dialog-title"
      fullWidth
      maxWidth="md"
    >
      <DialogTitle className={classes.titleDialog}>
        {notice?.titulo}
        <IconButton
          aria-label="close"
          className={classes.closeButton}
          onClick={handleClose}
        >
          <Close />
        </IconButton>
      </DialogTitle>
      <DialogContent dividers classes={{ root: classes.root }}>
        <Box
          className={classes.contentDialog}
          dangerouslySetInnerHTML={{ __html: notice?.conteudo || '' }}
        />
      </DialogContent>
    </Dialog>
  )
}
