import Box from '@material-ui/core/Box'
import Fab from '@material-ui/core/Fab'
import { Theme } from '@material-ui/core/styles'
import useScrollTrigger from '@material-ui/core/useScrollTrigger'
import Zoom from '@material-ui/core/Zoom'
import Navigation from '@material-ui/icons/Navigation'
import { createStyles, makeStyles } from '@material-ui/styles'
import React, {
  lazy,
  ReactElement,
  Suspense,
  useContext,
  useEffect,
  useRef,
} from 'react'
import { useHistory, useLocation } from 'react-router-dom'

import colorContext from '../../../Context/colorContext'
import RefContext from '../../../Context/refContext'
import { sendCodeForSISECI } from '../../../services/auth'
import Header from '../../Header'
import Licence from '../../Licence'
import SkeletonFooter from './skeletons/SkeletonFooter'

const Footer = lazy(() => import('../../Footer'))
interface Props {
  children: JSX.Element
  // eslint-disable-next-line react/require-default-props
  window?: () => Window
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      position: 'fixed',
      bottom: theme.spacing(10),
      right: theme.spacing(1),
      zIndex: 99999,
    },
  }),
)

function ScrollTop(props: Props) {
  const { children, window } = props
  const classes = useStyles()
  // Note that you normally won't need to set the window ref as useScrollTrigger
  // will default to window.
  // This is only being set here because the demo is in an iframe.
  const trigger = useScrollTrigger({
    target: window ? window() : undefined,
    disableHysteresis: true,
    threshold: 100,
  })

  const handleClick = (event: React.MouseEvent<HTMLDivElement>) => {
    const anchor = (
      (event.target as HTMLDivElement).ownerDocument || document
    ).querySelector('#back-to-top-anchor')

    if (anchor) {
      anchor.scrollIntoView({ behavior: 'smooth', block: 'center' })
    }
  }

  return (
    <Zoom in={trigger}>
      <div onClick={handleClick} role="presentation" className={classes.root}>
        {children}
      </div>
    </Zoom>
  )
}

export default function Dash(props: Props): ReactElement {
  const { colors } = useContext(colorContext)
  const { children } = props
  const history = useHistory()
  const location = useLocation()
  useEffect(() => {
    const url = (window as any).location.search
    const { pathname, search } = location
    const query = new URLSearchParams(url)
    const code: any = query.get('code')
    if (code) {
      const urlStorage = pathname + search
      localStorage.setItem('pathname_redirect_gov', urlStorage)
      sendCodeForSISECI(code, history)
    }
    const script = document.createElement('script')
    script.src = `https://www.google.com/recaptcha/api.js?render=${process.env.REACT_APP_RECAPTCHA}`
    script.defer = true
    script.addEventListener('load', () => {})
    document.body.appendChild(script)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])
  const refCategory = useRef(null)
  const refService = useRef(null)
  const refEstatistic = useRef(null)
  const refAttendance = useRef(null)
  const refNoticias = useRef(null)
  const refInicio = useRef(null)
  const refRegistryType = useRef(null)
  return (
    <div>
      <RefContext.Provider
        value={{
          refCategory,
          refService,
          refEstatistic,
          refAttendance,
          refNoticias,
          refInicio,
          refRegistryType,
        }}
      >
        <Box display="flex" flexDirection="column" minHeight="100vh">
          <Header />
          <Box flexGrow={1}>{children}</Box>
          <Suspense fallback={<SkeletonFooter />}>
            <Footer />
          </Suspense>
        </Box>
        <Licence />
        <ScrollTop {...props}>
          <Fab
            style={{
              right: 6,
              borderRadius: '50%',
              border: '1px solid #fff',
              backgroundColor: colors.colorWithoutGradient,
            }}
            size="large"
            color="primary"
            aria-label="scroll back to top"
          >
            <Navigation style={{ color: '#fff', width: 24, height: 24 }} />
          </Fab>
        </ScrollTop>
      </RefContext.Provider>
    </div>
  )
}
