import Button from '@material-ui/core/Button'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogContentText from '@material-ui/core/DialogContentText'
import { makeStyles } from '@material-ui/styles'
import React, { useContext } from 'react'
import { useLocation } from 'react-router-dom'

import colorContext from '../../Context/colorContext'
import { loginRedirectPrivider } from '../../services/auth'

interface Props {
  open: boolean
  onClose: () => void
}

export default function ModalNotAuth({ open, onClose }: Props): JSX.Element {
  const { colors } = useContext(colorContext)
  const useStyles = makeStyles(() => ({
    root: {
      backgroundColor: colors.colorBackground,
      color: colors.textBody,
    },
    title: {
      color: colors.textBody,
      backgroundColor: colors.colorBackground,
    },
    content: {
      color: colors.textBody,
    },
    actions: {
      backgroundColor: colors.colorBackground,
    },
    buttonCloser: {
      color: colors.accentColor,
      textTransform: 'none',
    },
    buttonLogin: {
      textTransform: 'none',
      color: colors.accentColor,
      border: `1px solid ${colors.accentColor}`,
    },
  }))
  const classes = useStyles()
  const location = useLocation()

  const loginRedirect = () => {
    const { pathname } = location
    loginRedirectPrivider(pathname)
  }

  return (
    <Dialog open={open} fullWidth maxWidth="sm">
      <DialogContent classes={{ root: classes.root }}>
        <DialogContentText className={classes.content}>
          Você ainda não se conectou ao RJDigital. Para ter uma melhor
          experiência e utilizar este serviço, clique no botão
          &quot;Entrar&quot;.
        </DialogContentText>
      </DialogContent>
      <DialogActions className={classes.actions}>
        <Button className={classes.buttonCloser} onClick={onClose}>
          Cancelar
        </Button>
        <Button className={classes.buttonLogin} onClick={loginRedirect}>
          Entrar
        </Button>
      </DialogActions>
    </Dialog>
  )
}
