/* eslint-disable @typescript-eslint/no-explicit-any */
import { createContext } from 'react'

interface ColorContextProps {
  typeTheme: string
  colors: {
    accentColor: string
    accentColorBackground: string
    colorBackground: string
    colorBackgroundSecundary: string
    textBody: string
    textAccentColor: string
    badgeColor: string
    cardAccentColor: string
    accordionTextColor: string
    serviceCardBackgroundColor: string
    favoriteBoxColor: string
    breadcrumbButtonColorHover: string
    borderShadowsColor: string
    buttonSuccessColor: string
    buttonErrorColor: string
    accentColorSecondary: string
    iconStepColor: string
    stepLabelColor: string
    menuItemColor: string
    subTitleColor: string
    colorGradient: string
    searchInputColor: string
    colorWithoutGradient: string
    colorSecondary: string
    borderShadowColorSecondary: string
    colorRJWithoutGradient: string
    colorPrimaryButton: string
    textBodyBack: string
    colorCarousel: string
    colorBreadcrumb: string
  }
  setTypeTheme?: (type: string) => void
}

const colorContext = createContext<ColorContextProps>({
  typeTheme: 'normal',
  colors: {
    accentColor: process.env.REACT_APP_COLOR_PRIMARY,
    accentColorBackground: process.env.REACT_APP_COLOR_PRIMARY,
    colorBackground: '#E8EBEF',
    colorBackgroundSecundary: '#ffffff',
    textBody: '#6B6D6E',
    textAccentColor: '#ffff',
    badgeColor: process.env.REACT_APP_COLOR_PRIMARY,
    cardAccentColor: process.env.REACT_APP_COLOR_PRIMARY,
    accordionTextColor: '#272727',
    serviceCardBackgroundColor: 'rgba(255, 255, 255, 0.5)',
    favoriteBoxColor: 'rgba(15, 111, 183, 0.05)',
    breadcrumbButtonColorHover: '#d4d4d4',
    borderShadowsColor: '#E1E6EF',
    buttonSuccessColor: '#3CD278',
    buttonErrorColor: '#F03D3D',
    accentColorSecondary: '#94C120',
    iconStepColor: '#94C120',
    stepLabelColor: '#ccc',
    menuItemColor: '#d4d4d4',
    subTitleColor: '#706E7A',
    colorGradient: 'linear-gradient(90deg, #192844 67.81%, #472774 100%)',
    searchInputColor: 'rgba(255, 255, 255, 0.85)',
    colorWithoutGradient: '#1F1F1F',
    colorSecondary: '#442771',
    borderShadowColorSecondary: '#00508C',
    colorRJWithoutGradient: '#192844',
    colorPrimaryButton: '#0BBBEF',
    textBodyBack: '#000000',
    colorCarousel: '#EEEEEE',
    colorBreadcrumb: '#192644',
  },
})

export default colorContext
