import { AxiosResponse } from 'axios'

import { apinoticias } from './api'

export const getAllNews = (page: number): Promise<AxiosResponse> => {
  if (process.env.REACT_APP_NOTICIAS_EXTERNAL === 'true') {
    return apinoticias.get(
      `${process.env.REACT_APP_NOTICIAS_EXTERNAL_ROUTER}?page=${page}`,
    )
  }
  return apinoticias.get(`/cms/noticias/?page=${page}`)
}

export const getNew = (slug: string): Promise<AxiosResponse> =>
  apinoticias.get(`/cms/noticias/?slug=${slug}`)

export const getNewForCategory = (
  category_slug: string,
  page: number,
): Promise<AxiosResponse> =>
  apinoticias.get(`/cms/noticias/?subtopico_slug=${category_slug}&page=${page}`)

export const searchNew = (text: string, page: number) =>
  apinoticias.get(
    `/cms/noticias/?search=${text.toLocaleUpperCase()}&page=${page}`,
  )

export const getSectionsList = (): Promise<AxiosResponse> =>
  apinoticias.get('/cms/secoes')

export const getPages = (slug: string): Promise<AxiosResponse> =>
  apinoticias.get(`/cms/paginas/?slug=${slug}`)

export const getMenus = (titulo: string): Promise<AxiosResponse> =>
  apinoticias.get(`/cms/menus/?titulo=${titulo}`)

export const getPhotos = (): Promise<AxiosResponse> =>
  apinoticias.get('/cms/fotos')

export const getSecretaries = (): Promise<AxiosResponse> =>
  apinoticias.get('/cms/secretarias')

export const getLogos = (): Promise<AxiosResponse> =>
  apinoticias.get('/cms/logos')

export const getAddress = (): Promise<AxiosResponse> =>
  apinoticias.get('/cms/endereco')

export const getLinksHeader = (): Promise<AxiosResponse> =>
  apinoticias.get('/cms/links-cabecalho')

export const getIcons = (): Promise<AxiosResponse> =>
  apinoticias.get('/cms/icones-redes-sociais')

export const getImageDefault = (): Promise<AxiosResponse> =>
  apinoticias.get('/cms/imagem-destaque-padrao')

export const getImageBackground = (): Promise<AxiosResponse> =>
  apinoticias.get('/cms/imagem-pesquisa')

export const listAllSubCategoryies = (): Promise<AxiosResponse> =>
  apinoticias.get('/cms/subcategorias')
