/* eslint-disable @typescript-eslint/no-unsafe-return */
/* eslint-disable @typescript-eslint/restrict-template-expressions */
import Avatar from '@material-ui/core/Avatar'
import Box from '@material-ui/core/Box'
import Button from '@material-ui/core/Button'
import CircularProgress from '@material-ui/core/CircularProgress'
import IconButton from '@material-ui/core/IconButton'
import Link from '@material-ui/core/Link'
import MenuItem from '@material-ui/core/MenuItem'
import Popover from '@material-ui/core/Popover'
import Snackbar from '@material-ui/core/Snackbar'
import { Theme } from '@material-ui/core/styles/createTheme'
import makeStyles from '@material-ui/core/styles/makeStyles'
import Typography from '@material-ui/core/Typography'
import useMediaQuery from '@material-ui/core/useMediaQuery'
import AccountCircle from '@material-ui/icons/AccountCircle'
import Dashboard from '@material-ui/icons/Dashboard'
import ExitToApp from '@material-ui/icons/ExitToApp'
import ExpandMore from '@material-ui/icons/ExpandMore'
import Menu from '@material-ui/icons/Menu'
import MoreVert from '@material-ui/icons/MoreVert'
import Alert from '@material-ui/lab/Alert'
import React, {
  Fragment,
  lazy,
  Suspense,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react'
import { Link as LinkRouter, useHistory, useLocation } from 'react-router-dom'

import ColorContext from '../../Context/colorContext'
import {
  clearStorage,
  getImageProfile,
  getUserData as getUserDataRequest,
  getUserDataComplements,
  loginRedirectPrivider,
  logout,
} from '../../services/auth'
import {
  getIcons,
  getLinksHeader,
  getLogos,
  getMenus,
} from '../../services/noticias'
import { getSubCategoriaPerfil } from '../../services/servico'
import { UserData } from '../../services/user'
import { cpfMask } from '../../utils/cpfFormat'
import { handleFilter } from '../../utils/handleFilter'
import { getInitialsFromName } from '../../utils/nameFormat'
import Links from '../Links'
import PlayerComponent from '../PlayerComponent'

const TermoDialog = lazy(() => import('../TermoDialog'))
const ModalAlertsDialog = lazy(() => import('../ModalAlertsDialog'))
const DrawerMenu = lazy(() => import('../DrawerMenu'))
const QuickAccessDrawer = lazy(() => import('../QuickAccessDrawer'))
const AlertDataComplements = lazy(() => import('../AlertDataComplements'))

export interface PropsNavLinks {
  icone: string
  id: number
  slug: string
  titulo: string
  subtopicos: [
    {
      icone: string
      id: number
      slug: string
      titulo: string
    },
  ]
}

interface PropsSubMenu {
  icone: string
  id: string
  link: string
  slug: string
  titulo: string
  link_externo: boolean
  pagina_slug: string
  pagina_publicado: boolean
}

export interface PropsLogos {
  titulo: string
  url_imagem: string
  url: string
  tipo: string
}

export interface PropsLinksHeader {
  ordem: number
  titulo: string
  url: string
  link_externo: boolean
}

export interface PropsIconsHeader {
  ordem: number
  titulo: string
  url_imagem: string
  url: string
}

interface TypeDataComplements {
  id: number
  created_at: string
  data_nascimento: string
  escolaridade: string
  pessoa: string
  profissao: string
  raca: string
  sexo: string
  updated_at: string
  endereco: string
  complemento: string
  bairro: string
  cidade: string
  estado: string
  cep: string
  observacoes: string
  zona: string
  tipo_logradouro: string
  nome_logradouro: string
  numero: string
}

export default function Header(): JSX.Element {
  const { colors, setTypeTheme, typeTheme } = useContext(ColorContext)

  const useStyles = makeStyles((theme: Theme) => ({
    main: {
      width: '100%',
      minHeight: 141,
      height: 'auto',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      padding: '0px 10px',
      background: colors.colorGradient,
      color: colors.textAccentColor,
    },
    text: {
      textTransform: 'capitalize',
      fontFamily: 'Roboto',
      margin: '0px 15px',
      color: colors.textBody,
      cursor: 'pointer',
    },
    textButton: {
      fontFamily: 'Roboto',
      fontWeight: 400,
      fontStyle: 'normal',
      fontSize: theme.typography.pxToRem(14),
      color: colors.colorSecondary,
      padding: '4px 15px',
    },
    textName: {
      fontSize: theme.typography.pxToRem(14),
      fontWeight: 'bold',
      textTransform: 'capitalize',
      color: colors.accentColor,
    },
    textNamePopOver: {
      fontSize: theme.typography.pxToRem(14),
      fontWeight: 'bold',
      textTransform: 'capitalize',
      color: colors.textBody,
    },
    avatar: {
      height: 32,
      width: 32,
      backgroundColor: colors.colorBackground,
      marginRight: 10,
      border: `1px solid ${colors.accentColor}`,
    },
    menuItem: {
      minHeight: 42,
      color: '#737B7D',
      minWidth: 236,
      backgroundColor: colors.colorBackground,
      '&:hover': {
        background: colors.colorBackground,
      },
    },
    textMenuItem: {
      fontSize: theme.typography.pxToRem(14),
      marginLeft: 10,
      fontFamily: 'Roboto',
      fontWeight: 500,
      color: colors.textBody,
    },
    textCpf: {
      fontSize: theme.typography.pxToRem(10),
      fontWeight: 400,
      textAlign: 'start',
      fontFamily: 'Roboto',
      color: colors.accentColor,
    },
    textCpfPopOver: {
      fontSize: theme.typography.pxToRem(10),
      fontWeight: 400,
      textAlign: 'start',
      fontFamily: 'Roboto',
      color: colors.textBody,
    },
    buttonAccessibility: {
      display: 'inline-block',
      padding: 6,
      minWidth: 0,
      minHeight: 0,
      fontSize: 14,
      justifyContent: 'center',
      alignItems: 'center',
      color: colors.accentColor,
    },
    boxAccessibility: {
      display: 'flex',
      alignItems: 'center',
      width: 30,
      justifyContent: 'center',
      height: 30,
      borderRadius: '50%',
      backgroundColor: colors.accentColor,
      marginRight: 8,
      padding: 0,
    },
    boxSubMenu: {
      height: 32,
      width: '100%',
      display: 'flex',
      backgroundColor: colors.accentColorBackground,
    },
    boxSub: {
      width: '1050px',
      display: 'flex',
      justifyContent: 'space-between',
      flexDirection: 'row-reverse',
      alignItems: 'center',
    },
    linksBoxSubMenu: {
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
      marginBottom: 7,
      minWidth: 742.93,
    },
    textLocale: {
      display: 'flex',
      alignItems: 'center',
      padding: '3px 15px',
      fontSize: theme.typography.pxToRem(12),
      fontFamily: 'Roboto',
      fontWeight: 500,
      color: '#3E3E3E',
      borderLeft: '1px solid #DBDFE2',
      borderRight: '1px solid #DBDFE2',
    },
    textFontContrast: {
      fontSize: theme.typography.pxToRem(14),
      fontWeight: 500,
      color: colors.textBody,
    },
    boxInfo: {
      fontFamily: 'Roboto',
      fontWeight: 400,
      color: colors.accentColor,
    },
    btnSocial: {
      color: colors.textAccentColor,
      padding: 0,
      margin: '0 10px',
    },
    boxIcon: {
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'end',
      alignItems: 'center',
    },
    iconSocial: {
      width: 18,
      height: 18,
    },
    boxLogin: {
      width: 'auto',
      maxWidth: 119,
      height: 40,
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      borderRadius: 15,
      backgroundColor: colors.searchInputColor,
      '&:hover': {
        backgroundColor: colors.breadcrumbButtonColorHover,
      },
    },
    textLink: {
      cursor: 'pointer',
      fontWeight: 400,
      fontSize: theme.typography.pxToRem(12),
      textAlign: 'center',
      textDecoration: 'none',
      color: colors.textAccentColor,
      textTransform: 'uppercase',
    },
    loginIcon: {
      height: 25,
      width: 25,
      color: colors.colorSecondary,
    },
    divider: {
      color: colors.accentColor,
      margin: '0px 10px',
    },
    dividerWhite: {
      color: '#fff',
      margin: '0px 10px',
    },
    navBar: {
      width: '100%',
      height: 83,
      backgroundColor: colors.colorBackgroundSecundary,
      display: 'flex',
      justifyContent: 'center',
      position: 'relative',
    },
    navBarLinks: {
      width: '100%',
      display: 'flex',
      maxWidth: '1320px',
      alignItems: 'center',
      justifyContent: 'space-between',
      [theme.breakpoints.down('md')]: {
        display: 'none',
      },
    },
    links: {
      fontSize: theme.typography.pxToRem(18),
      fontWeight: 700,
      color: colors.accentColor,
      textTransform: 'uppercase',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      '&:hover': {
        color: '#fff',
      },
    },
    iconNavBar: {
      marginRight: 10,
    },
    img: {
      maxWidth: 513,
      width: '100%',
      [theme.breakpoints.down('xs')]: {
        maxWidth: 247,
        height: 'auto',
      },
    },
    boxRadio: {
      width: '100%',
      height: 28,
      borderRadius: 10,
      backgroundColor: '#192844',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
    },
    buttonPlay: {
      width: 40,
      height: 28,
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      backgroundColor: '#442771',
      borderTopLeftRadius: 10,
      borderBottomLeftRadius: 10,
      padding: 11,
      cursor: 'pointer',
    },
    textTitleRadio: {
      fontSize: theme.typography.pxToRem(8),
      fontStyle: 'normal',
      fontWeight: 400,
      lineHeight: '0.9',
      textAlign: 'center',
      textTransform: 'uppercase',
    },
    textSubTitleRadio: {
      fontSize: theme.typography.pxToRem(10),
      fontStyle: 'normal',
      fontWeight: 400,
      textAlign: 'center',
      textTransform: 'uppercase',
    },
    buttonDrop: {
      padding: '0px !important',
      '&:focus': {
        boxShadow: 'unset !important',
      },
    },
    dropItem: {
      width: 'fit-content',
    },
    slugLinks: {
      textDecoration: 'none',
      color: 'inherit',
    },
    dropdown: {
      position: 'absolute',
      top: 67,
      left: 0,
      width: '100%',
      minHeight: '300px',
      backgroundColor: '#fff',
      zIndex: 5,
    },
    listUl: {
      display: 'flex',
      justifyContent: 'center',
      // backgroundColor: '#ccc',
    },
    listLi: {
      padding: 5,
      whiteSpace: 'nowrap',
      cursor: 'pointer',
    },
    infoHeaderRight: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'flex-end',
      [theme.breakpoints.down('md')]: {
        display: 'none',
      },
    },
    navigationMobile: {
      padding: '0px 20px',
      width: '100%',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
      [theme.breakpoints.up('lg')]: {
        display: 'none',
      },
    },
    iconQuickAccess: {
      [theme.breakpoints.up('lg')]: {
        display: 'none',
      },
    },
    drawerActions: {
      top: 224,
      height: 'calc(100vh - 215px)',
      backgroundColor: colors.colorBackgroundSecundary,
    },
    titleDrawer: {
      margin: '24px 20px',
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
    },
    textDrawer: {
      fontFamily: 'Roboto',
      fontSize: theme.typography.pxToRem(14),
      fontWeight: 700,
      textTransform: 'uppercase',
    },
    accordionRoot: {
      backgroundColor: colors.colorBackgroundSecundary,
      border: 'none',
      padding: '0px 20px',
    },
    listDrawer: {
      fontFamily: 'Roboto',
      fontWeight: 400,
      fontSize: theme.typography.pxToRem(12),
      color: colors.textAccentColor,
      marginLeft: 10,
    },
    listAcordeons: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
    },
    accordion: {
      '&.MuiAccordion-root:before': {
        backgroundColor: 'transparent',
      },
    },
    accordionDetails: {
      display: 'flex',
      flexDirection: 'column',
    },
    boxLinksMobile: {
      display: 'flex',
      alignItems: 'center',
      marginTop: 20,
    },
    linksMobile: {
      textDecoration: 'none',
      marginLeft: 8,
      color: colors.textBody,
      fontWeight: 400,
      fontSize: theme.typography.pxToRem(14),
      fontFamily: 'Roboto',
    },
    player: {
      display: 'none',
    },
  }))
  const classes = useStyles()
  const history = useHistory()
  const location = useLocation()
  const matches = useMediaQuery('(min-width:1200px)')
  const matchesMobile = useMediaQuery('(min-width:660px)')
  const [isPlaying, setIsPlaying] = useState(false)
  const token: string | null = localStorage.getItem('gov_access_token_sso')
  const tokenSiseci: string | null = localStorage.getItem(
    'gov_access_token_siseci',
  )
  const hasComplements = sessionStorage.getItem('@data-complements')
  const [openDrawerNav, setOpenDrawerNav] = useState(false)
  const [openDrawerQuickAccess, setOpenDrawerQuickAccess] = useState(false)
  const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(null)
  const [anchorElUser, setAnchorElUser] =
    React.useState<HTMLButtonElement | null>(null)
  const [userData, setUserData] = useState<UserData | null>()
  const [message, setMessage] = useState<string>('')
  const [avatarUser, setAvatarUser] = useState<string>('')
  const [loading, setLoading] = useState(false)
  const [logoHeader, setLogoHeader] = useState<PropsLogos>()
  const [linksHeader, setLinksHeader] = useState<PropsLinksHeader[]>()
  const [iconsHeader, setIconsHeader] = useState<PropsIconsHeader[]>()
  const [, setPerson] = useState<PropsNavLinks[]>(null)
  const [, setEnterprise] = useState<PropsNavLinks[]>(null)
  const [, setPublicAdmin] = useState<PropsNavLinks[]>(null)
  const [, setServer] = useState<PropsNavLinks[]>(null)
  const [navs, setNavs] = useState<any>([
    {
      nome: 'Governo',
      icon: {
        name: 'governo',
        width: '38px',
        height: '34px',
      },
      list: [],
    },
    {
      nome: 'Cidadão',
      route_name: 'cidadao',
      icon: {
        name: 'cidadao',
        width: '8px',
        height: '24px',
      },
      list: [],
      actionPage: 'cidadao',
    },
    {
      nome: 'Servidor',
      route_name: 'servidor',
      icon: {
        name: 'servidor',
        width: '26px',
        height: '26px',
      },
      list: [],
      actionPage: 'servidor',
    },
    {
      nome: 'Empresa',
      route_name: 'empresa',
      icon: {
        name: 'empresa',
        width: '25px',
        height: '25px',
      },
      list: [],
      actionPage: 'empresa',
    },
    {
      nome: 'Gestão pública',
      route_name: 'gestao_publica',
      icon: {
        name: 'gestao',
        width: '21px',
        height: '25px',
      },
      list: [],
      actionPage: 'gestao_publica',
    },
    {
      nome: 'Imprensa',
      icon: {
        name: 'imprensa',
        width: '31px',
        height: '20px',
      },
      list: [],
    },
    {
      nome: 'Atendimento',
      icon: {
        name: 'atendimento',
        width: '19px',
        height: '21px',
      },
      list: [],
    },
  ])
  const [openModalComplements, setOpenModalComplements] =
    useState<boolean>(false)
  const [, setDataComplements] = useState<TypeDataComplements>()

  const [anchorElHover, setAnchorElHover] = React.useState(null)

  const handlePlayer = () => setIsPlaying(!isPlaying)

  function handleHover(name: string) {
    if (anchorElHover !== name) {
      setAnchorElHover(name)
    }
  }

  function handleCloseMenu() {
    setAnchorElHover(null)
  }

  // eslint-disable-next-line react-hooks/exhaustive-deps
  function handleSwitchColor() {
    setTypeTheme(typeTheme === 'normal' ? 'contraste' : 'normal')
  }

  async function handleIconsHeader() {
    const { data } = await getIcons()
    setIconsHeader(data)
  }

  async function handleLinkHeaders() {
    const { data } = await getLinksHeader()
    setLinksHeader(data)
  }
  async function handleGetLogos() {
    try {
      const { data } = await getLogos()
      const resultFiltred = handleFilter(data, 'Cabeçalho')

      setLogoHeader(resultFiltred[0])
    } catch (error) {
      // eslint-disable-next-line no-console
      console.log(error)
    }
  }

  useEffect(() => {
    const { state } = location
    if (state as any) {
      setMessage((state as any).message)
    }
  }, [location])

  const getUserData = async (tokenSso: string, tokenSiseciReceive: string) => {
    try {
      setLoading(true)

      const data = await getUserDataRequest(tokenSso, tokenSiseciReceive)

      setUserData(data)
      localStorage.setItem('gov_user_data', JSON.stringify(data))
      // setLoading(false);
    } catch (err) {
      clearStorage()
      if (err.response?.data) {
        setMessage(err.response.data.detail)
      }
    } finally {
      setLoading(false)
    }
  }

  useEffect(() => {
    if (token && tokenSiseci) {
      getUserData(token, tokenSiseci)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    if (token && tokenSiseci) {
      getUserData(token, tokenSiseci)
    }
  }, [token, tokenSiseci])

  const handleClick = (
    event: React.MouseEvent<HTMLButtonElement>,
    type?: 'user' | 'access',
  ) => {
    if (type === 'user') {
      setAnchorElUser(event.currentTarget)
    } else {
      setAnchorEl(event.currentTarget)
    }
  }

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const handleClose = () => {
    setAnchorEl(null)
    setAnchorElUser(null)
  }

  const isWorkspace = location.pathname.startsWith('/workspace')

  const logoutUser = async () => {
    await logout()
    if (isWorkspace) {
      history.push('/')
    }
    setUserData(undefined)
    setAnchorElUser(null)
  }

  useEffect(() => {
    const handleGetImageProfile = async () => {
      if (token) {
        const { data } = await getImageProfile(token)

        if (data?.results?.length > 0) {
          const image = data.results[0].arquivo
          setAvatarUser(image)
        }
      }
    }

    handleGetImageProfile()
  }, [token])

  const preventDefault = (event: React.SyntheticEvent) => event.preventDefault()

  const renderMenus = (): JSX.Element => (
    <>
      {!matches && (
        <>
          <IconButton onClick={handleClick} color="primary">
            <Menu style={{ color: colors.accentColor }} />
          </IconButton>
        </>
      )}
      <Suspense fallback="">
        <TermoDialog />
      </Suspense>
      <Suspense fallback="">
        <ModalAlertsDialog />
      </Suspense>
      {/* <AlertAcceptCookies />  Foi adicionado um script para exibir um modal de cookies no lugar desse. */}
    </>
  )

  const handleVerifyDataComplements = async () => {
    if (token) {
      try {
        const { data } = await getUserDataComplements(token)
        if (data) {
          setDataComplements(data?.results[0])
          sessionStorage.setItem('@data-complements', 'true')
        }
      } catch (error) {
        if (error?.response?.status === 404) {
          sessionStorage.setItem('@data-complements', 'false')
          setOpenModalComplements(true)
        }
      }
    }
  }

  const loginRedirect = () => {
    const urlLocation = location.pathname + location.search
    loginRedirectPrivider(urlLocation)
  }

  const nameSplitOne = (name: string): string => {
    if ((name || '').split(' ').length > 2) {
      return `${name.split(' ')[0]}`
    }
    return name
  }

  const nameSplitTwo = (name: string): string => {
    if ((name || '').split(' ').length > 2) {
      return `${name.split(' ')[0]} ${name.split(' ')[1][0]}.`
    }
    return name
  }

  const handleOpenDrawerMenu = useCallback(() => {
    setOpenDrawerNav((oldDrawer) => !oldDrawer)
  }, [])

  const handleOpenDrawerQuickAccess = useCallback(() => {
    setOpenDrawerQuickAccess((oldDrawer) => !oldDrawer)
  }, [])

  const renderItems = useMemo((): JSX.Element => {
    const menus = [
      {
        titulo: 'Governo',
        url: '',
        external_url: 'https://www.rjdrupal.proderj.rj.gov.br/sobre-governador',
      },
      {
        titulo: 'Cidadão',
        url: '/cidadao/servicos',
      },
      {
        titulo: 'Servidor',
        url: '/servidor/servicos',
      },
      {
        titulo: 'Empresa',
        url: '/empresa/servicos',
      },
      {
        titulo: 'Gestão pública',
        url: '/gestao/servicos',
      },
      {
        titulo: 'Imprensa',
        url: '',
        external_url:
          'https://www.rjdrupal.proderj.rj.gov.br/assessoria-de-imprensa',
      },
      {
        titulo: 'Atendimento',
        url: '',
        external_url:
          'https://falabr.cgu.gov.br/publico/RJ/Manifestacao/RegistrarManifestacao',
      },
      {
        titulo: 'Contraste',
        url: '#',
        click: true,
      },
      {
        titulo: 'Acessibilidade',
        url: '/acessibilidade',
      },
    ]

    return (
      <>
        {menus.map((menu) => (
          <MenuItem
            key={menu.titulo}
            style={{
              backgroundColor: colors.colorBackground,
            }}
          >
            <Link
              color="primary"
              href={menu.url}
              onClick={(e: React.SyntheticEvent): void => {
                preventDefault(e)
                if (menu.external_url) {
                  ;(window as any).open(menu.external_url)
                } else {
                  history.push(menu.url)
                }
                if (menu.click) {
                  handleSwitchColor()
                }
                handleClose()
              }}
              className={classes.text}
            >
              {menu.titulo}
            </Link>
          </MenuItem>
        ))}
      </>
    )
  }, [
    colors.colorBackground,
    classes.text,
    history,
    handleSwitchColor,
    handleClose,
  ])

  const handleFormatLinksMenu = (menuData: PropsSubMenu[]) =>
    menuData.map((menu) => ({
      titulo: menu.titulo,
      url: menu.link,
      isExternal: menu.link_externo,
      pagina_publicado: menu.pagina_publicado || (menu?.link && true),
      pagina_slug: menu.pagina_slug,
      slug: menu.slug,
    }))

  const handleGetSubPerson = async () => {
    try {
      const { data } = await getSubCategoriaPerfil('Cidadão')
      setPerson(data)
      if (data) {
        const dataNav = navs
        const dataFormatted = data.map((arr) => ({
          ...arr,
          pagina_publicado: true,
        }))
        dataNav[1].list = dataFormatted
        setNavs(dataNav)
      }
    } catch (error) {
      console.log(error)
    }
  }

  const handleGetSubServer = async () => {
    try {
      const { data } = await getSubCategoriaPerfil('Servidor')
      setServer(data)
      if (data) {
        const dataNav = navs
        const dataFormatted = data.map((arr) => ({
          ...arr,
          pagina_publicado: true,
        }))
        dataNav[2].list = dataFormatted
        setNavs(dataNav)
      }
    } catch (error) {
      console.log(error)
    }
  }

  const handleGetSubEnterprise = async () => {
    try {
      const { data } = await getSubCategoriaPerfil('Empresa')
      setEnterprise(data)
      if (data) {
        const dataNav = navs
        const dataFormatted = data.map((arr) => ({
          ...arr,
          pagina_publicado: true,
        }))
        dataNav[3].list = dataFormatted
        setNavs(dataNav)
      }
    } catch (error) {
      console.log(error)
    }
  }

  const handleGetSubPublicAdmin = async () => {
    try {
      const { data } = await getSubCategoriaPerfil('Gestão Pública')
      setPublicAdmin(data)
      if (data) {
        const dataNav = navs
        const dataFormatted = data.map((arr) => ({
          ...arr,
          pagina_publicado: true,
        }))
        dataNav[4].list = dataFormatted
        setNavs(dataNav)
      }
    } catch (error) {
      console.log(error)
    }
  }

  const handleGetGovernoMenu = async () => {
    try {
      const { data } = await getMenus('Governo')
      if (data?.results[0]?.submenu_menu[0]) {
        const menusResponse = data?.results[0]?.submenu_menu
        const newData = handleFormatLinksMenu(menusResponse)
        const dataNav = navs
        dataNav[0].list = newData
        setNavs(dataNav)
      }
    } catch (error) {
      console.log(error)
    }
  }

  const handleGetImprensaMenu = async () => {
    try {
      const { data } = await getMenus('Imprensa')
      if (data?.results[0]?.submenu_menu[0]) {
        const menusResponse = data?.results[0]?.submenu_menu
        const newData = handleFormatLinksMenu(menusResponse)
        const dataNav = navs
        dataNav[5].list = newData
        setNavs(dataNav)
      }
    } catch (error) {
      console.log(error)
    }
  }

  const handleGetAtendimentoMenu = async () => {
    try {
      const { data } = await getMenus('Atendimento')
      if (data?.results[0]?.submenu_menu[0]) {
        const menusResponse = data?.results[0]?.submenu_menu
        const newData = handleFormatLinksMenu(menusResponse)
        const dataNav = navs
        dataNav[6].list = newData
        setNavs(dataNav)
      }
    } catch (error) {
      console.log(error)
    }
  }

  useEffect(() => {
    if (token && userData && !hasComplements) {
      handleVerifyDataComplements()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [token, userData])

  useEffect(() => {
    if (hasComplements === 'false') {
      setOpenModalComplements(true)
    }
  }, [hasComplements])

  async function promiseAll() {
    await Promise.all([
      handleGetLogos(),
      handleLinkHeaders(),
      handleIconsHeader(),
      handleGetGovernoMenu(),
      handleGetImprensaMenu(),
      handleGetAtendimentoMenu(),
      handleGetSubPerson(),
      handleGetSubEnterprise(),
      handleGetSubPublicAdmin(),
      handleGetSubServer(),
    ])
  }

  useEffect(() => {
    promiseAll()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <>
      <Box id="back-to-top-anchor" style={{ top: 0 }} className={classes.main}>
        <Snackbar
          autoHideDuration={3000}
          onClose={(): void => setMessage('')}
          open={!!message}
        >
          <Alert onClose={(): void => setMessage('')} severity="error">
            {message}
          </Alert>
        </Snackbar>
        <Popover
          className={classes.boxInfo}
          open={Boolean(anchorEl)}
          anchorEl={anchorEl}
          onClose={handleClose}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'left',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'left',
          }}
        >
          {renderItems}
        </Popover>
        <Popover
          open={Boolean(anchorElUser)}
          anchorEl={anchorElUser}
          onClose={handleClose}
          anchorOrigin={{
            vertical: 'top',
            horizontal: 'right',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'right',
          }}
        >
          <Box>
            {userData && (
              <MenuItem
                style={{
                  backgroundColor: colors.colorBackground,
                }}
              >
                <Avatar
                  src={avatarUser || null}
                  alt={getInitialsFromName(userData.nome || '')}
                  className={classes.avatar}
                />
                <Box
                  display="flex"
                  flexDirection="column"
                  alignItems="flex-start"
                >
                  <Typography className={classes.textNamePopOver}>
                    {matchesMobile
                      ? nameSplitTwo(userData?.nome?.toLowerCase())
                      : nameSplitOne(userData?.nome?.toLowerCase())}
                  </Typography>
                  <Typography className={classes.textCpfPopOver}>
                    {cpfMask(userData.cpf)}
                  </Typography>
                </Box>
              </MenuItem>
            )}
            <MenuItem className={classes.menuItem}>
              <a
                href="/workspace"
                rel="noopener noreferrer"
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  width: '100%',
                  textDecoration: 'none',
                  fontFamily: 'Roboto',
                  fontSize: 16,
                  fontWeight: 500,
                }}
              >
                <Dashboard style={{ color: colors.accentColor }} />
                <Typography className={classes.textMenuItem}>Meu RJ</Typography>
              </a>
            </MenuItem>
            {/* <Divider  /> */}
            <MenuItem onClick={logoutUser} className={classes.menuItem}>
              <ExitToApp style={{ color: colors.buttonErrorColor }} />
              <Typography className={classes.textMenuItem}>Sair</Typography>
            </MenuItem>
            {/* <Divider /> */}
          </Box>
        </Popover>
        <Box
          display="flex"
          maxWidth="1320px"
          alignItems="center"
          width="100%"
          justifyContent="space-between"
          flexWrap="wrap"
        >
          <Box width="513px" height="163px">
            {logoHeader?.url_imagem && (
              <a href={logoHeader?.url || '/'}>
                <img
                  className={classes.img}
                  width="513px"
                  height="163px"
                  alt="RJ"
                  src={logoHeader?.url_imagem}
                  loading="eager"
                  fetchPriority="high"
                />
              </a>
            )}
          </Box>
          <Box className={classes.iconQuickAccess}>
            <IconButton onClick={() => setOpenDrawerQuickAccess(true)}>
              <MoreVert style={{ color: 'white', fontSize: 30 }} />
            </IconButton>
          </Box>
          <Box className={classes.infoHeaderRight}>
            {matches ? (
              <>
                <Box className={classes.linksBoxSubMenu}>
                  <LinkRouter to="/acessibilidade" className={classes.textLink}>
                    ACESSIBILIDADE
                  </LinkRouter>
                  <span className={classes.divider}>|</span>
                  <Box onClick={() => handleSwitchColor()}>
                    <Typography className={classes.textLink}>
                      CONTRASTE
                    </Typography>
                  </Box>
                  {linksHeader?.length > 0 &&
                    linksHeader.map((link) => (
                      <Fragment key={`${link.titulo}-key`}>
                        <span className={classes.divider}>|</span>
                        <a
                          href={link.url}
                          target={link.link_externo ? '_blank' : '_parent'}
                          className={classes.textLink}
                          rel="noreferrer"
                        >
                          {link.titulo}
                        </a>
                      </Fragment>
                    ))}
                </Box>
              </>
            ) : (
              ''
            )}
            <Box
              className={classes.boxIcon}
              style={{ flexWrap: !matches ? 'wrap' : 'nowrap' }}
            >
              <Box className={classes.boxRadio} onClick={() => handlePlayer()}>
                <PlayerComponent
                  handlePlay={handlePlayer}
                  isPlaying={isPlaying}
                />
                <div
                  style={{
                    display: 'flex',
                    flexDirection: 'column',
                    padding: '3px 8px',
                  }}
                >
                  <Typography className={classes.textTitleRadio}>
                    OUÇA AO VIVO - A RÁDIO DO RIO - 94 FM
                  </Typography>
                  <Typography className={classes.textSubTitleRadio}>
                    RÁDIO ROQUETTE PINTO
                  </Typography>
                </div>
              </Box>
              {renderMenus()}
              {!matches && (
                <Box display="flex" flex-direction="row" alignItems="center">
                  {loading ? (
                    <CircularProgress
                      style={{ width: 25, height: 25 }}
                      color="primary"
                    />
                  ) : (
                    <>
                      {userData ? (
                        <Button onClick={(e): void => handleClick(e, 'user')}>
                          <Avatar
                            src={avatarUser || null}
                            alt={getInitialsFromName(userData.nome || '')}
                            className={classes.avatar}
                          />
                          <Box
                            display="flex"
                            flexDirection="column"
                            alignItems="flex-start"
                          >
                            <Typography className={classes.textName}>
                              {matchesMobile
                                ? nameSplitTwo(userData?.nome?.toLowerCase())
                                : nameSplitOne(userData?.nome?.toLowerCase())}
                            </Typography>
                            <Typography
                              color="primary"
                              className={classes.textCpf}
                            >
                              {cpfMask(userData.cpf)}
                            </Typography>
                          </Box>
                          <ExpandMore style={{ color: colors.accentColor }} />
                        </Button>
                      ) : (
                        <Button
                          className={classes.boxLogin}
                          onClick={loginRedirect}
                        >
                          <div style={{ marginLeft: '15px', marginTop: 4 }}>
                            <AccountCircle className={classes.loginIcon} />
                          </div>
                          <Typography className={classes.textButton}>
                            ENTRAR
                          </Typography>
                        </Button>
                      )}
                    </>
                  )}
                </Box>
              )}
              <Box minWidth={270}>
                {iconsHeader && (
                  <>
                    <span className={classes.dividerWhite}>|</span>
                    {iconsHeader.map((icon) => (
                      <IconButton
                        key={`${icon.titulo}-key`}
                        href={icon.url}
                        target="_blank"
                      >
                        <img
                          width="25px"
                          height="25px"
                          src={icon.url_imagem}
                          alt={icon.titulo}
                          loading="lazy"
                        />
                      </IconButton>
                    ))}
                  </>
                )}
              </Box>
            </Box>
          </Box>
        </Box>
      </Box>
      <Box className={classes.navBar}>
        <Box className={classes.navigationMobile}>
          <IconButton
            onClick={() => setOpenDrawerNav(true)}
            size="medium"
            type="button"
          >
            <Menu style={{ fontSize: 40, color: 'white' }} />
          </IconButton>
          <Box display="flex" flex-direction="row" alignItems="center">
            {loading ? (
              <CircularProgress
                style={{ width: 25, height: 25 }}
                color="primary"
              />
            ) : (
              <>
                {userData ? (
                  <Button onClick={(e): void => handleClick(e, 'user')}>
                    <Avatar
                      src={avatarUser || null}
                      alt={getInitialsFromName(userData.nome || '')}
                      className={classes.avatar}
                    />
                    <Box
                      display="flex"
                      flexDirection="column"
                      alignItems="flex-start"
                    >
                      <Typography className={classes.textName}>
                        {matchesMobile
                          ? nameSplitTwo(userData?.nome?.toLowerCase())
                          : nameSplitOne(userData?.nome?.toLowerCase())}
                      </Typography>
                      <Typography color="primary" className={classes.textCpf}>
                        {cpfMask(userData.cpf)}
                      </Typography>
                    </Box>
                    <ExpandMore style={{ color: colors.accentColor }} />
                  </Button>
                ) : (
                  <Button className={classes.boxLogin} onClick={loginRedirect}>
                    <div style={{ marginLeft: '15px', marginTop: 4 }}>
                      <AccountCircle className={classes.loginIcon} />
                    </div>
                    <Typography className={classes.textButton}>
                      ENTRAR
                    </Typography>
                  </Button>
                )}
              </>
            )}
          </Box>
        </Box>
        <Box className={classes.navBarLinks}>
          {navs.map((nav, index) => (
            <Links
              key={index.toString()}
              label={nav.nome}
              list={nav.list}
              icon={nav.icon}
              route={nav.route_name}
              actionPage={nav.actionPage}
              onMouseEnter={() => {
                handleHover(nav.nome)
              }}
              onMouseLeave={() => handleCloseMenu()}
              active={nav.nome === anchorElHover}
            />
          ))}
          <Box display="flex" flex-direction="row" alignItems="center">
            {loading ? (
              <CircularProgress
                style={{ width: 25, height: 25 }}
                color="primary"
              />
            ) : (
              <>
                {userData ? (
                  <Button onClick={(e): void => handleClick(e, 'user')}>
                    <Avatar
                      src={avatarUser || null}
                      alt={getInitialsFromName(userData.nome || '')}
                      className={classes.avatar}
                    />
                    <Box
                      display="flex"
                      flexDirection="column"
                      alignItems="flex-start"
                    >
                      <Typography className={classes.textName}>
                        {matchesMobile
                          ? nameSplitTwo(userData?.nome?.toLowerCase())
                          : nameSplitOne(userData?.nome?.toLowerCase())}
                      </Typography>
                      <Typography color="primary" className={classes.textCpf}>
                        {cpfMask(userData.cpf)}
                      </Typography>
                    </Box>
                    <ExpandMore style={{ color: colors.accentColor }} />
                  </Button>
                ) : (
                  <Button className={classes.boxLogin} onClick={loginRedirect}>
                    <div style={{ marginLeft: '15px', marginTop: 4 }}>
                      <AccountCircle className={classes.loginIcon} />
                    </div>
                    <Typography className={classes.textButton}>
                      ENTRAR
                    </Typography>
                  </Button>
                )}
              </>
            )}
          </Box>
        </Box>
      </Box>
      <Box>
        <Suspense fallback="">
          <DrawerMenu
            navigationList={navs}
            isOpen={openDrawerNav}
            handleOpenDrawerNav={handleOpenDrawerMenu}
          />
        </Suspense>
        <Suspense fallback="">
          <QuickAccessDrawer
            icons={iconsHeader}
            links={linksHeader}
            isOpen={openDrawerQuickAccess}
            handleOpenDrawerQuickAccess={handleOpenDrawerQuickAccess}
          />
        </Suspense>
      </Box>
      <Suspense fallback="">
        <AlertDataComplements
          handleClose={() => setOpenModalComplements(false)}
          open={openModalComplements}
        />
      </Suspense>
    </>
  )
}
