import Box from '@material-ui/core/Box'
import { Theme } from '@material-ui/core/styles/createTheme'
import makeStyles from '@material-ui/core/styles/makeStyles'
import Typography from '@material-ui/core/Typography'
import React, { useContext } from 'react'

import colorContext from '../../Context/colorContext'

export default function Licence(): JSX.Element {
  const { colors } = useContext(colorContext)
  const useStyles = makeStyles((theme: Theme) => ({
    main: {
      width: '100%',
      minHeight: 60,
      justifyContent: 'center',
      backgroundColor: colors.colorBackground,
      color: colors.textBody,
      display: 'none',
    },
    title: {
      fontSize: theme.typography.pxToRem(14),
      fontWeight: 400,
    },
    nameCompany: {
      fontSize: theme.typography.pxToRem(14),
      fontWeight: 700,
    },
  }))
  const classes = useStyles()
  return (
    <Box className={classes.main}>
      <Box
        display="flex"
        maxWidth="1052px"
        width="100%"
        flexDirection="column"
        alignItems="center"
        justifyContent="center"
      >
        <Typography className={classes.title}>
          Todo o conteúdo deste site está publicado sob a licença
          <span className={classes.nameCompany}>
            {' '}
            {process.env.REACT_APP_LICENCE}
          </span>
        </Typography>
      </Box>
    </Box>
  )
}
