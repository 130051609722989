import Dialog from '@material-ui/core/Dialog'
import DialogContent from '@material-ui/core/DialogContent'
import DialogContentText from '@material-ui/core/DialogContentText'
import DialogTitle from '@material-ui/core/DialogTitle'
import IconButton from '@material-ui/core/IconButton'
import { Theme } from '@material-ui/core/styles/createTheme'
import makeStyles from '@material-ui/core/styles/makeStyles'
import Close from '@material-ui/icons/Close'
import React from 'react'

interface ModalUnauthorizedUserProps {
  open: boolean
  handleClose: () => void
}

export default function ModalUnauthorizedUser({
  open,
  handleClose,
}: ModalUnauthorizedUserProps): JSX.Element {
  const useStyles = makeStyles((theme: Theme) => ({
    dialogTitle: {
      color: '#FFF',
      backgroundImage: 'linear-gradient(90deg, #192844 61.11%, #472774 100%)',
    },
    closeIcon: {
      position: 'absolute',
      right: 8,
      top: 8,
      color: '#FFF',
    },
    button: {
      border: 'none',
      width: 200,
      cursor: 'pointer',
      height: 48,
      borderRadius: 6,
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      color: '#ffff',
      backgroundColor: '#192844',
      [theme.breakpoints.down('sm')]: {
        maxWidth: '100%',
        display: 'none',
      },
    },
    content: {
      color: '#192844',
      padding: '1rem 0',
    },
  }))

  const classes = useStyles()

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-title" className={classes.dialogTitle}>
        Aviso
      </DialogTitle>
      <IconButton
        aria-label="close"
        onClick={handleClose}
        className={classes.closeIcon}
      >
        <Close />
      </IconButton>
      <DialogContent>
        <DialogContentText
          id="alert-dialog-description"
          className={classes.content}
        >
          Este serviço é de acesso exclusivo para o perfil Servidor
        </DialogContentText>
      </DialogContent>
    </Dialog>
  )
}
