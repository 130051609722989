import React, { createContext, useState } from 'react'

interface SocialContextData {
  imagem_perfil: string
  twitter_url: string
  data: {
    id: string
    text: string
    url: string
    url_photo: string
  }[]
}

interface Props {
  children?: React.ReactNode
}

const initialState = {
  imagem_perfil: '',
  twitter_url: '',
  data: [
    {
      id: '',
      text: '',
      url: '',
      url_photo: '',
    },
  ],
}

const SocialContext = createContext<any>(initialState)

function SocialProvider({ children }: Props): JSX.Element {
  const [socialData, setSocialData] = useState<SocialContextData>()
  return (
    <SocialContext.Provider value={{ socialData, setSocialData }}>
      {children}
    </SocialContext.Provider>
  )
}

export { SocialContext, SocialProvider }
