/* eslint-disable react/require-default-props */
import Box from '@material-ui/core/Box'
import Breadcrumbs from '@material-ui/core/Breadcrumbs'
import IconButton from '@material-ui/core/IconButton'
import Link from '@material-ui/core/Link'
import { Theme } from '@material-ui/core/styles/createTheme'
import makeStyles from '@material-ui/core/styles/makeStyles'
import Typography from '@material-ui/core/Typography'
import useMediaQuery from '@material-ui/core/useMediaQuery'
import Home from '@material-ui/icons/Home'
import Search from '@material-ui/icons/Search'
import React, { useContext, useState } from 'react'
import { useHistory } from 'react-router-dom'

import colorContext from '../../Context/colorContext'

interface Info {
  titulo: string
  slug: string
  id?: any
}
interface Props {
  audience?: Info
  category?: Info | any
  subCategory?: Info
  service?: string
}

export default function Breadcrumb({
  audience,
  category,
  subCategory,
  service,
}: Props): JSX.Element {
  const { colors } = useContext(colorContext)
  const useStyles = makeStyles((theme: Theme) => ({
    main: {
      width: '100%',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      backgroundColor: colors.colorBreadcrumb,
      [theme.breakpoints.down('lg')]: {
        padding: '0 32px',
      },
    },
    link: {
      display: 'flex',
    },
    icon: {
      marginRight: theme.spacing(0.5),
      color: colors.textAccentColor,
      width: 30,
      height: 30,
    },
    inputSearch: {
      height: 50,
      borderRadius: 0,
      width: '100%',
      border: 'none',
      outline: 'none',
      paddingLeft: 16,
      color: colors.textBody,
      background: colors.colorBackground,
      '&::placeholder': {
        color: colors.textBody,
        fontSize: theme.typography.pxToRem(14),
        fontStyle: 'italic',
        fontWeight: 400,
      },
    },
    iconSearch: {
      background: colors.colorBackground,
    },
    breadcrumbs: {
      flexGrow: 1,
      color: colors.textAccentColor,
    },
    textBreadcrumbs: {
      color: colors.textAccentColor,
      display: 'block',
      overflow: 'hidden',
      whiteSpace: 'nowrap',
      textOverflow: 'ellipsis',
      maxWidth: '300px',
      textTransform: 'capitalize',
      fontWeight: 400,
      fontSize: 18,
    },
    boxButtonSearch: {
      background: colors.colorBackground,
    },
    buttonSearch: {
      width: 86,
      height: 38,
      borderRadius: 5,
      backgroundColor: colors.colorWithoutGradient,
      margin: '6px 12px',
    },
    buttonSearchText: {
      padding: '11px 19px',
      color: colors.accentColor,
      fontSize: 14,
      fontWeight: 400,
      textTransform: 'uppercase',
    },
    linkStyle: {
      textDecoration: 'none !important',
    },
    subTitle: {
      color: colors.textAccentColor,
      display: 'block',
      overflow: 'hidden',
      whiteSpace: 'nowrap',
      textOverflow: 'ellipsis',
      maxWidth: '300px',
      textTransform: 'capitalize',
      fontFamily: 'Roboto',
      fontSize: 18,
      fontWeight: 400,
      [theme.breakpoints.down('xs')]: {
        fontSize: 14,
        width: '100%',
      },
    },
    form: {
      display: 'flex',
      justifyContent: 'flex-end',
      flexGrow: 1,
      width: '100%',
      margin: '24px 20px',
      height: 45,
      [theme.breakpoints.down('xs')]: {
        width: '100%',
        justifyContent: 'center',
        minWidth: 'unset',
      },
      [theme.breakpoints.only('md')]: {
        with: '100%',
        minWidth: 'unset',
      },
      [theme.breakpoints.up('lg')]: {
        maxWidth: 481,
      },
    },
  }))
  const classes = useStyles()
  const history = useHistory()
  const [textSearch, setTextSearch] = useState<string>('')
  const matches = useMediaQuery('(min-width:935px)')
  const submitSearch = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault()
    if (textSearch) {
      const formattedSearchText = textSearch.replaceAll('/', '--')
      history.push(`/buscar/q=${formattedSearchText}`)
      setTextSearch('')
    }
  }
  return (
    <Box className={classes.main}>
      <Box
        display="flex"
        maxWidth="1320px"
        alignItems="center"
        width="100%"
        flexWrap="wrap"
        justifyContent={matches ? 'space-between' : 'flex-end'}
      >
        <Breadcrumbs separator="/" className={classes.breadcrumbs}>
          <Link
            href="/"
            onClick={(e: React.SyntheticEvent) => {
              e.preventDefault()
              history.push('/')
            }}
          >
            <Home className={classes.icon} />
          </Link>
          {category || subCategory || service ? (
            <Link
              href={
                audience?.slug === 'noticias'
                  ? '/noticias'
                  : `/${audience?.slug}/servicos`
              }
              className={classes.linkStyle}
            >
              <Typography className={classes.textBreadcrumbs}>
                {audience?.titulo === 'Notícias'
                  ? 'Notícias'
                  : audience?.titulo}
              </Typography>
            </Link>
          ) : (
            <Typography className={classes.textBreadcrumbs}>
              {audience?.titulo}
            </Typography>
          )}
          {category && subCategory ? (
            <Link
              href={`/${audience?.slug}/servicos/${
                category.slug || category
              }/categoria/${category.id || category}`}
              className={classes.linkStyle}
            >
              <Typography className={classes.textBreadcrumbs}>
                {category.titulo || category}
              </Typography>
            </Link>
          ) : undefined}
          {category && !subCategory ? (
            <Typography className={classes.textBreadcrumbs}>
              {category.titulo || category}
            </Typography>
          ) : undefined}
          {subCategory && category && service ? (
            <Link
              href={`/${audience?.slug}/servicos/${subCategory.slug}/sub_categoria/${subCategory.id}`}
              className={classes.linkStyle}
            >
              <Typography className={classes.textBreadcrumbs}>
                {subCategory.titulo}
              </Typography>
            </Link>
          ) : undefined}
          {subCategory && !category && service ? (
            <Typography className={classes.textBreadcrumbs}>
              {subCategory.titulo}
            </Typography>
          ) : undefined}
          {subCategory && category && !service ? (
            <Typography className={classes.textBreadcrumbs}>
              {subCategory.titulo}
            </Typography>
          ) : undefined}
          {service ? (
            <Typography className={classes.textBreadcrumbs}>
              {service}
            </Typography>
          ) : undefined}
        </Breadcrumbs>
        <form
          onSubmit={submitSearch}
          style={{
            display: 'flex',
            flexGrow: 1,
            maxWidth: 600,
            marginTop: 15,
            marginBottom: 15,
            height: 50,
            backgroundColor: colors.colorBackground,
          }}
        >
          <div
            className={classes.iconSearch}
            style={{
              padding: 12,
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              borderRadius: '0px',
            }}
          >
            <Search
              style={{ color: colors.borderShadowColorSecondary, fontSize: 30 }}
              // style={{ color: colors.borderShadowColorSecondary }}
              // size={30}
            />
          </div>
          <input
            value={textSearch}
            onChange={(e): void => setTextSearch(e.target.value)}
            className={classes.inputSearch}
            style={{
              width: '100%',
              height: 50,
            }}
            placeholder="Exemplo: IPVA, Portal do Servidor.."
          />
          <IconButton type="submit" className={classes.buttonSearch}>
            <Typography className={classes.buttonSearchText}>Buscar</Typography>
          </IconButton>
        </form>
      </Box>
    </Box>
  )
}
