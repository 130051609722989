import Box from '@material-ui/core/Box'
import Button from '@material-ui/core/Button'
import CircularProgress from '@material-ui/core/CircularProgress'
import Grid from '@material-ui/core/Grid'
import { Theme } from '@material-ui/core/styles/createTheme'
import makeStyles from '@material-ui/core/styles/makeStyles'
import Typography from '@material-ui/core/Typography'
import Print from '@material-ui/icons/Print'
import QrCode from 'qrcode.react'
import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'

import { getHistorico } from '../../services/agendamento'
import dateFormatToBR from '../../utils/dateFormat'
// Create styles
const useStyles = makeStyles((theme: Theme) => ({
  boxHeader: {
    backgroundColor: theme.palette.primary.main,
    color: '#FFFFFF',
    padding: 20,
  },
  textName: {
    fontSize: '24px',
    lineHeight: '24px',
    letterSpacing: '0.1px',
  },
  textCpf: {
    fontWeight: 300,
    fontSize: '16px',
    lineHeight: '24px',
    textTransform: 'capitalize',
  },
  textNumberCounter: {
    fontWeight: 'bold',
    fontSize: '24px',
    lineHeight: '24px',
    letterSpacing: '0.1px',
  },
  textCounter: {
    fontWeight: 300,
    fontSize: '16px',
    lineHeight: '24px',
    textTansform: 'uppercase',
  },
  textUnidade: {
    fontWeight: 'normal',
    fontSize: '18px',
    lineHeight: '23px',
    textTransform: 'capitalize',
    color: '#737B7D',
    paddingBottom: '20px',
    paddingTop: '20px',
  },
  textService: {
    fontStyle: 'normal',
    fontWeight: 600,
    fontSize: '18px',
    lineHeight: '22px',
    color: '#373F41',
  },
  textOrgan: {
    fontWeight: 300,
    fontSize: '16px',
    lineHeight: '22px',
    color: '#373F41',
    paddingBottom: '20px',
  },
  textDateTime: {
    fontWeight: 300,
    fontSize: '16px',
    lineHeight: '24px',
    color: '#373F41',
  },
  textTicket: {
    paddingTop: '20px',
    fontWeight: 600,
    fontSize: '16px',
    lineHeight: '22px',
    color: '#373F41',
    paddingBottom: '20px',
  },
  btnPrint: {
    backgroundColor: '#3CD278',
    border: 'none',
    color: '#FFFFFF',
    borderRadius: '0',
    width: '60%',
    textTransform: 'capitalize',
    padding: '10px',
    boxShadow: 'none',
    '&:hover': {
      backgroundColor: '#00a04b',
    },
  },
  boxActions: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    paddingBottom: '25px',
  },
}))

// Create Document Component
export default function SchedulePrint(): JSX.Element {
  const classes = useStyles()

  const [loading, setLoading] = useState<boolean>(true)
  const [schedule, setSchedule] = useState(null)

  const { id }: any = useParams()

  const getHistory = async (): Promise<void> => {
    const token: string | null = localStorage.getItem('gov_access_token_siseci')
    setLoading(true)
    if (token) {
      try {
        const { data } = await getHistorico(token, id)
        setSchedule(data)
        setLoading(false)
      } catch (err) {
        console.log(err)
        setLoading(false)
      }
    }
  }

  useEffect(() => {
    const captcha = document.getElementsByClassName('grecaptcha-badge')

    if (captcha && captcha.length) {
      captcha[0].remove()
    }
  }, [schedule])

  useEffect(() => {
    const enable = document.getElementsByClassName('enabled')
    if (enable && enable.length) {
      enable[0].remove()
    }
    getHistory()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id])

  useEffect(() => {
    if (!loading) {
      window.print()
    }
  }, [loading])

  return (
    <Box style={{ padding: 20 }} id="print">
      {loading && (
        <Box
          marginBottom="60px"
          width="100%"
          display="flex"
          justifyContent="center"
        >
          <CircularProgress />
        </Box>
      )}

      {!loading && schedule && (
        <>
          <Grid
            container
            justify="space-between"
            alignItems="flex-start"
            className={classes.boxHeader}
          >
            <Grid item>
              <Typography className={classes.textName}>
                {schedule?.user?.first_name}
              </Typography>
              <Typography className={classes.textCpf}>
                {schedule?.user?.username}
              </Typography>
            </Grid>
            <Grid item>
              <Box style={{ width: '75px', textAlign: 'center' }}>
                <Typography className={classes.textNumberCounter}>
                  {schedule?.guiche?.ordem}
                </Typography>
                <Typography className={classes.textCounter}>GUICHÊ</Typography>
              </Box>
            </Grid>
          </Grid>

          <Grid container>
            <Grid item style={{ paddingBottom: '20px' }}>
              <Typography className={classes.textUnidade}>
                {schedule?.unidade?.nome.toLowerCase()}
              </Typography>
              <Typography className={classes.textService}>
                {schedule?.servico?.titulo}
              </Typography>

              <Typography className={classes.textOrgan}>
                {`Órgão: ${schedule?.orgao_responsavel}`}
              </Typography>

              <Typography className={classes.textDateTime}>
                Data da solicitação:
                <Typography component="strong">
                  {' '}
                  {` ${dateFormatToBR(schedule?.data)}`}
                </Typography>
              </Typography>

              <Typography className={classes.textDateTime}>
                Horário da solicitação:{' '}
                <Typography component="strong">{schedule?.hora}</Typography>
              </Typography>
            </Grid>
          </Grid>
          <Grid container justify="space-around" alignItems="flex-start">
            <Grid item xs={8}>
              {schedule?.hora_inicio && (
                <>
                  <Typography className={classes.textDateTime}>
                    Data do atendimento:
                    <Typography component="strong">
                      {' '}
                      {` ${dateFormatToBR(schedule?.data)}`}
                    </Typography>
                  </Typography>
                  <Typography className={classes.textDateTime}>
                    Horário do atendimento:
                    <Typography component="strong">
                      {' '}
                      {schedule?.hora_inicio}
                    </Typography>
                  </Typography>
                </>
              )}

              <Typography
                className={classes.textDateTime}
                style={{ marginBottom: 20 }}
              >
                Dependente:{' '}
                <Typography component="strong">
                  {schedule?.dependentes ? 'Sim' : 'Não'}
                </Typography>
                {schedule?.dependentes && (
                  <>
                    <Typography className={classes.textDateTime}>
                      Nome:{' '}
                      <Typography component="strong">
                        {schedule?.dependentes.nome}
                      </Typography>
                    </Typography>
                    <Typography className={classes.textDateTime}>
                      CPF:{' '}
                      <Typography component="strong">
                        {schedule?.dependentes.cpf}
                      </Typography>
                    </Typography>
                    <Typography className={classes.textDateTime}>
                      Grau de parentesco:{' '}
                      <Typography component="strong">
                        {schedule?.dependentes.grau_parentesco}
                      </Typography>
                    </Typography>
                  </>
                )}
              </Typography>

              <Typography className={classes.textDateTime}>
                Status do atendimento:
                <Typography component="strong"> {schedule?.status}</Typography>
              </Typography>
              <Typography className={classes.textTicket}>
                {schedule?.ticket}
              </Typography>
            </Grid>
            <Grid item xs={4}>
              <QrCode value={schedule?.ticket} size={180} />
            </Grid>
          </Grid>
          <Grid container justify="center">
            <Grid item>
              <Button
                variant="contained"
                color="primary"
                className="print_none"
                onClick={() => window.print()}
              >
                <style>{'@media print {.print_none{display: none;}}'}</style>{' '}
                <Print /> <Typography>IMPRIMIR</Typography>
              </Button>
            </Grid>
          </Grid>
        </>
      )}
    </Box>
  )
}
